
import { Component, Prop, Vue } from "vue-property-decorator";

import { LocalisationUi } from "@/domain";

import {
  FilterTypes,
  MeasureTableDetails,
  UiMeasure,
} from "prometheus-synced-ui";

import Loading from "@/views/Loading.vue";
import TableAutocomplete from "@/components/General/Autocompletes/ExamReports/TableByPartialZoneAutocomplete.vue";
import { TableDisplay } from "prometheus-synced-ui";

@Component({
  components: {
    Loading,
    TableAutocomplete,
    TableDisplay,
  },
})
export default class HistoricComparisonItem extends Vue {
  @Prop({ required: true })
  table!: MeasureTableDetails | null;

  @Prop({ required: true })
  measures!: UiMeasure[];

  @Prop({ required: true })
  otherTableId!: string | null;

  @Prop({ required: true })
  localisation!: LocalisationUi;

  @Prop({ required: true })
  loading!: string;

  @Prop({ required: true })
  label!: string;

  @Prop({ required: true })
  filter!: FilterTypes;

  @Prop({ required: true })
  showSpecialCell!: boolean;

  @Prop({ required: true })
  showOffCriteria!: boolean;
}

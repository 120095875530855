
import { Component, Vue } from "vue-property-decorator";
import _ from "lodash";

import { LocalisationUi } from "@/domain";

import HistoricComparisonItem from "@/components/Core/ExamReports/Historic/Comparison/HistoricComparisonItem.vue";
import HistoricSelection from "@/components/Core/ExamReports/Historic/Comparison/HistoricSelection.vue";
import ZoneSelection from "@/components/Core/ExamReports/Historic/ZoneSelection.vue";

@Component({
  components: {
    ZoneSelection,
    HistoricComparisonItem,
    HistoricSelection,
  },
})
export default class HistoricComparison extends Vue {
  get powerPlantId(): string | null {
    return (
      (this.$route.params.powerPlantId && this.$route.params.powerPlantId) ||
      null
    );
  }

  get nuclearUnitId(): string | null {
    return (
      (this.$route.params.nuclearUnitId && this.$route.params.nuclearUnitId) ||
      null
    );
  }

  get systemId(): string | null {
    return (this.$route.params.systemId && this.$route.params.systemId) || null;
  }

  get equipmentId(): string | null {
    return (
      (this.$route.params.equipmentId && this.$route.params.equipmentId) || null
    );
  }

  get zoneId(): string | null {
    return (this.$route.params.zoneId && this.$route.params.zoneId) || null;
  }

  get fromDashboard(): boolean {
    return this.$route.params.fromDashboard == "true";
  }

  get firstTableId(): string | null {
    return (
      (this.$route.params.firstTableId && this.$route.params.firstTableId) ||
      null
    );
  }

  get secondTableId(): string | null {
    return (
      (this.$route.params.secondTableId && this.$route.params.secondTableId) ||
      null
    );
  }

  currentFirstTableId: string | null = null;
  currentSecondTableId: string | null = null;
  showHistoricSelection: boolean = false;
  currentLocalisation: LocalisationUi = {
    powerPlantId: null,
    nuclearUnitId: null,
    systemId: null,
    equipmentId: null,
    zoneId: null,
  };
  finalLocalisation: LocalisationUi = {
    powerPlantId: null,
    nuclearUnitId: null,
    systemId: null,
    equipmentId: null,
    zoneId: null,
  };

  mounted() {
    this.currentLocalisation = {
      powerPlantId: this.powerPlantId,
      nuclearUnitId: this.nuclearUnitId,
      systemId: this.systemId,
      equipmentId: this.equipmentId,
      zoneId: this.zoneId,
    };
    this.finalLocalisation = this.currentLocalisation;
    this.showHistoricSelection = this.fromDashboard;
    this.currentFirstTableId = this.firstTableId;
    this.currentSecondTableId = this.secondTableId;
  }

  update() {
    this.finalLocalisation = _.clone(this.currentLocalisation);
    this.currentFirstTableId = null;
    this.currentSecondTableId = null;
    this.showHistoricSelection = true;
  }
}


import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import { lightFormat } from "date-fns";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { EMPTY, PROVIDER, SERVICES as S } from "@/config/literals";

import { IMeasureTableService } from "@/services";

import { LocalisationUi, MeasureTableEssential } from "@/domain";
import { TableOrigin } from "prometheus-synced-ui";

@Component({
  filters: {
    pretty(date: Date | null): string {
      return date != null ? lightFormat(date, "dd/MM/yyyy") : EMPTY;
    },
  },
  data: () => {
    return {
      TableOrigin,
    };
  },
})
export default class TableAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  idToExclude!: string | null;

  @Prop({ required: true })
  localisation!: LocalisationUi;

  @Prop({ required: false, default: "Sélection Historique" })
  label!: string;

  get measureTableService() {
    return this.container.resolve<IMeasureTableService>(
      S.MEASURE_TABLE_SERVICE
    );
  }

  get filteredTables() {
    let result: MeasureTableEssential[] = [];
    result =
      this.idToExclude != null
        ? _.filter(this.tables, (t) => t.id != this.idToExclude)
        : this.tables;
    return _.filter(result, (t) => t.containsMeasures);
  }

  tables: MeasureTableEssential[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  getOriginLabel(origin: TableOrigin) {
    return origin == TableOrigin.technisonic ? "Technisonic" : "EDF";
  }

  async fetch() {
    try {
      this.loading = true;
      this.tables = await this.measureTableService.getEssentialByPartialZone({
        powerPlantId: this.localisation.powerPlantId,
        nuclearUnitId: this.localisation.nuclearUnitId,
        systemId: this.localisation.systemId,
        equipmentId: this.localisation.equipmentId,
        zoneId: this.localisation.zoneId,
      });
    } finally {
      this.loading = false;
    }
  }

  reset() {
    this.$emit("input", null);
    this.fetch();
  }

  @Watch("localisation")
  onPropChange = this.reset;
}


import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class HistoricComparisonMessage extends Vue {
  @Prop({ required: true })
  differentValuesCondition!: boolean;

  @Prop({ required: true })
  sameValuesCondition!: boolean;

  @Prop({ required: true })
  differences!: number;

  @Prop({ required: true })
  sameDimension!: boolean;

  @Prop({ required: true })
  sameHeaders!: boolean;
}

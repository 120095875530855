
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { LocalisationUi } from "@/domain";

import {
  FilterTypes,
  HeaderInfos,
  MeasureTableDetails,
  UiMeasure,
} from "prometheus-synced-ui";

import HistoricComparisonItem from "@/components/Core/ExamReports/Historic/Comparison/HistoricComparisonItem.vue";
import HistoricComparisonMessage from "@/components/Core/ExamReports/Historic/Comparison/HistoricComparisonMessage.vue";
import FilterInputs from "@/components/General/Table/FilterInputs.vue";

import { IMeasureTableService } from "@/services";

@Component({
  components: {
    HistoricComparisonItem,
    HistoricComparisonMessage,
    FilterInputs,
  },
})
export default class HistoricSelection extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  localisation!: LocalisationUi;

  @Prop({ required: true })
  firstTableId!: string | null;

  @Prop({ required: true })
  secondTableId!: string | null;

  get measureTableService() {
    return this.container.resolve<IMeasureTableService>(
      S.MEASURE_TABLE_SERVICE
    );
  }

  get hasSameDimensions(): boolean {
    return (
      this.firstTable == null ||
      this.secondTable == null ||
      (this.firstTable!.columnHeaders.length ==
        this.secondTable!.columnHeaders.length &&
        this.firstTable!.lineHeaders.length ==
          this.secondTable!.lineHeaders.length)
    );
  }

  get hasSameHeaders(): boolean {
    if (this.firstTable != null && this.secondTable != null) {
      if (!this.hasSameDimensions) {
        return false;
      }
      for (const columnHeader of this.firstTable!.columnHeaders) {
        if (!this.findHeader(columnHeader, this.secondTable!.columnHeaders)) {
          return false;
        }
      }
      for (const lineHeader of this.firstTable!.lineHeaders) {
        if (!this.findHeader(lineHeader, this.secondTable!.lineHeaders)) {
          return false;
        }
      }
    }
    return true;
  }

  get differenceNumber(): number {
    if (this.firstMeasures.length > 0 && this.secondMeasures.length > 0) {
      return _.filter(this.firstMeasures, (m) => m.isSpecialCell).length;
    }
    return 0;
  }

  fetching: boolean = false;
  currentFirstTableId: string | null = null;
  currentSecondTableId: string | null = null;
  firstTable: MeasureTableDetails | null = null;
  secondTable: MeasureTableDetails | null = null;
  firstMeasures: UiMeasure[] = [];
  secondMeasures: UiMeasure[] = [];
  filter: FilterTypes = FilterTypes.none;
  showOffCriteria: boolean = false;

  mounted() {
    this.currentFirstTableId = this.firstTableId;
    this.currentSecondTableId = this.secondTableId;
    if (this.shouldFetch()) {
      this.fetch();
    }
  }

  async fetch() {
    try {
      this.fetching = true;
      this.firstTable = await this.measureTableService.get(
        this.currentFirstTableId!
      );
      this.secondTable = await this.measureTableService.get(
        this.currentSecondTableId!
      );
      this.firstMeasures = this.firstTable.getUiMeasures(
        this.hasSameDimensions ? this.secondTable : undefined
      );
      this.secondMeasures = this.secondTable.getUiMeasures(
        this.hasSameDimensions ? this.firstTable : undefined
      );
    } finally {
      this.fetching = false;
    }
  }

  findHeader(targetHeader: HeaderInfos, targetTable: HeaderInfos[]): boolean {
    return (
      _.findIndex(
        targetTable,
        (t) => t.index == targetHeader.index && t.value == targetHeader.value
      ) != -1
    );
  }

  updateFirst(tableId: string | null) {
    this.currentFirstTableId = tableId;
    this.firstTable = null;
    if (this.shouldFetch()) {
      this.fetch();
    }
  }

  updateSecond(tableId: string | null) {
    this.currentSecondTableId = tableId;
    this.secondTable = null;
    if (this.shouldFetch()) {
      this.fetch();
    }
  }

  shouldFetch(): boolean {
    return (
      this.currentFirstTableId != null && this.currentSecondTableId != null
    );
  }
}
